<template>
    <CardComponent
        :title="title"
        :icon="icon"
        link="/supply-chain/daily-report"
        class="store-monitor-card"
    >
        <template v-slot:cardOptions>
            <el-select
                v-model="brandId"
                style="width: 1.2rem;"
                placeholder="请选择"
                size="small"
                @change="vendorChange()"
            >
                <el-option
                    v-for="item in brands"
                    :key="item.id"
                    :label="item.brandName"
                    :value="item.id"
                >
                </el-option>
            </el-select>
        </template>
        <div
            :class="{
                'store-monitor-main': true,
                'store-monitor-main_small': size === 'samll'
            }"
        >
            <el-table
                v-loading="loading"
                class="store-monitor-table"
                :data="tableData"
                height="3.4rem"
                style="width: 100%;"
            >
                <el-table-column v-if="!size" prop="brandName" label="品牌">
                </el-table-column>
                <el-table-column
                    prop="skuTitle"
                    show-overflow-tooltip
                    label="货品"
                >
                </el-table-column>
                <el-table-column
                    v-if="!size"
                    prop="stockQuantity"
                    label="合计库存"
                >
                </el-table-column>
                <el-table-column
                    v-if="!size"
                    prop="monthStock"
                    label="平均库存"
                >
                </el-table-column>
                <el-table-column
                    v-if="!size"
                    prop="weekQuantity"
                    label="日销（7天）"
                >
                </el-table-column>
                <el-table-column
                    min-width="90px"
                    prop="sellDays"
                    label="可售天数"
                    sortable
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.sellDaysAlert == 1" style="color: red;">
                            {{ scope.row.sellDays }}
                        </span>
                        <span v-else>
                            {{ scope.row.sellDays }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column
                    min-width="100px"
                    prop="turnoverValue"
                    label="周转周期"
                    sortable
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.turnoverAlert == 1" style="color: red;">
                            {{ scope.row.turnoverValue }}
                        </span>
                        <span v-else>
                            {{ scope.row.turnoverValue }}
                        </span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </CardComponent>
</template>

<script>
import CardComponent from '../components/card'
import tradeSvc from 'services/trade'
import productSvc from 'services/product'
export default {
    name: '',
    components: { CardComponent },
    props: {
        size: {
            type: String,
            default: null
        }
    },
    data () {
        return {
            title: '库存监控',
            icon: 'iconfont icon-kucunjiankong',
            tableData: [],
            loading: false,
            brands: [],
            brandId: 0
        }
    },
    created () {
        this.getBrands()
        this.getStockMonitor()
    },
    methods: {
        async getBrands () {
            try {
                const { data = [] } = await productSvc.getBrands()
                data.unshift({
                    id: 0,
                    brandName: '全部'
                })
                this.brands = data
            } catch (error) {
                console.log(error)
            }
        },
        async getStockMonitor () {
            try {
                this.loading = true
                let param = {
                    brandId: this.brandId
                }
                const { data = [] } = await tradeSvc.getStockMonitor(param)
                let finalData = data.filter(item => item.turnoverAlert == '1' || item.sellDaysAlert == '1')
                this.stockMonitorData = finalData
                this.tableData = finalData
                this.loading = false
            } catch (error) {
                console.log(error)
                this.loading = false
            }
        },
        vendorChange () {
            this.getStockMonitor()
        }
    }
}
</script>
<style lang="less" scoped>
.store-monitor {
    &-card {
        grid-row-start: span 2;
        grid-column-start: span 3;

        /deep/ .main {
            padding: 0;

            .com-card-header {
                padding: 0.2rem;
            }
        }
    }

    &-main {
        padding: 0 0.2rem;
    }

    &-main_small {
        padding: 0 0.1rem;
    }

    /deep/ &-table {
        font-size: 0.14rem;
        color: #606266;

        thead {
            .cell {
                font-size: 0.14rem;
                font-weight: 400;
                color: #a7b6c3;
            }
        }

        tr,
        th,
        td {
            // background-color: #f2f5f9;
            border: none;
        }

        .el-table__body {
            td {
                color: #606266;
            }
        }
    }

    /deep/ &-table::before {
        height: 0 !important;
    }

    .mark-red {
        color: red;
    }
}
</style>
